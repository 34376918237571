import React, { useState } from 'react';

import ReactPlayer from 'react-player';
import './VideoPlayer.css';

import { useInView } from 'react-intersection-observer';

const VideoPlayer = (props) => {
    const [playState, setPlayState] = useState(true);
    const [isPlayClicked, setIsPlayClicked] = useState(false);
    const [showPauseBtn, setShowPauseBtn] = useState(false);
    const [player, setPlayer] = useState('');
    const [showStopBtn, setShowStopBtn] = useState(false);
    const [showPlayBtn, setShowPlayBtn] = useState(false);
    const [muted, setMuted] = useState(true)
    const unMuted = () => {
      setMuted(false);
    }
    const THRESHOLD = [0.25, 0.5, 0.75];
    const [ref, entry] = useInView({ threshold: THRESHOLD });
    const pause = () => {
      setPlayState(false);
      // hide pause btn
      setShowPauseBtn(false);
      // show play btn
      setShowPlayBtn(true);
      setIsPlayClicked(false);
    }
  
    const play = () => {
        setPlayState(true);
        setShowPlayBtn(false);
        setIsPlayClicked(true);
    }
  
    const stop = () => {
       player.showPreview();
      setShowStopBtn(false);
      setIsPlayClicked(false);
      setShowPlayBtn(false);
      setShowPauseBtn(false);
      props.onVideoStart('overlay-slide');
      setTimeout(function(){ props.onVideoStart('reset-overlay');}, 100);
    }
  
    const showControls = () => {
      setTimeout(function(){ pause()}, 100);
    }
  
    const hideControls = () => {
      // hide pause btn
      setShowPauseBtn(false);
    }
  
    const playVideo = () => {
      setIsPlayClicked(true);
      setShowStopBtn(true);
      props.onVideoStart('overlay-slide');
      setTimeout(function(){ props.onVideoStart('hide');}, 700); 
      setTimeout(function(){unMuted(false)}, 3000);
    }

    const resetVideo = () => {
      stop();
    }
  
    const refEle = (player) => {
      setPlayer(player);
    }

   const trackVisibility = (entry) => {
     if ((entry.intersectionRatio === 0 || entry.intersectionRatio < 0.4) && isPlayClicked ) {
       console.log('stop video')
       resetVideo();
     }
   }
   
    return (
      <>
      <div 
        className={`video_overlay ${isPlayClicked ? 'show' : 'hide'}`}
        onClick={showControls}
        onMouseLeave={hideControls}
      ></div>
      
        <div
          ref={ref}
          className="video-wrapper"
          >
            {entry && trackVisibility(entry)}
          <ReactPlayer
            ref={refEle}
            url='https://movement-assets.imgix.net/media/movementfoundation/videos/More-Than-Mortgages.mp4'
            className='react-player'
            width="100%"
            height="100%"  
            playing={playState}
            muted = {muted}
            playIcon={<button id="play-video" className="video-play-button"><span></span></button>}
            light="assets/dummy.png"    
            onStart={(e) => playVideo()}
            onEnded={(e) => resetVideo()}
          />
        </div>
        <div
         className={`pause ${showPauseBtn ? 'show' : 'hide' }`}
         onClick={pause}
         onMouseEnter={showControls}
        ></div>
        <div
         className={`play ${showPlayBtn ? 'show' : 'hide'}`}
         onClick={play}
        ></div>
        <div
         className={`stop ${showStopBtn ? 'show' : 'hide'}`}
         onClick={stop}
        ></div>
      </>
    );
  };

  export default VideoPlayer;
