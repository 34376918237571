import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <>
        <footer>
            <div className="footer-links">
                <div className="about dotted-border">
                <img src="https://movement-assets.imgix.net/media/movementfoundation/mm_icon.png" className="footer-icon" alt="movement-icon"/>
                <a href="https://www.movement.com" target="_blank" rel="noopener noreferrer">
                  <span>
                    About Movement <br className="footer-brk" />Mortgage
                    <img src="https://movement-assets.imgix.net/media/movementfoundation/arrow.png" alt="arrow"/>
                    </span>
                </a>
            </div>
                <div className="career dotted-border">
                        <img src="https://movement-assets.imgix.net/media/movementfoundation/career_icon.png" className="footer-icon" alt="carreer-icon"/>
                        <a href="https://j.brt.mv/ax.do?refresh=true&portalGK=40995" target="_blank" rel="noopener noreferrer">
                          <span>
                            Careers <br className="responsive" /> at movement
                            <img src="https://movement-assets.imgix.net/media/movementfoundation/arrow.png" alt="arrow"/>
                          </span>
                        </a>
                </div>
                <div className="press">
                        <img src="https://movement-assets.imgix.net/media/movementfoundation/press_icon.png" className="footer-icon"alt="press-icon"/>
                        <a href="https://movement.com/press" target="_blank" rel="noopener noreferrer">
                          <span>
                              Media <br className="responsive" /> &amp; Press
                              <img src="https://movement-assets.imgix.net/media/movementfoundation/arrow.png" alt="arrow"/>
                          </span>
                        </a>
                </div>
            </div>           
           <img src="https://movement-assets.imgix.net/media/movementfoundation/Logo.png" className="logo-img" alt="movement-logo"/>
           <div className="footer-txt">
           Movement Foundation's Match Giving program is targeted toward Movement Mortgage team members. Outside requests will not be considered at this time.
                
           </div>
           <div className="footer-copy">
                <h6 className="footer-right-border">8024 Calvin Hall Rd. Indian Land, SC 29707</h6>
                <h6 className="footer-right-border-withCopyright">Copyright &copy;2017 Movement Foundation</h6>
                <h6 className="footer-right-border-withoutCopyright">&copy;2017 Movement Foundation</h6>
           </div>
        </footer>
        </>
    );
};

export default Footer;