import React, { useState } from 'react';
import VideoPlayer from './../VideoPlayer/VideoPlayer';

import './WhyWeExist.css';
import 'react-modal-video/css/modal-video.min.css';
import YouTubePlayer from './../YoutubePlayer/YoutubePlayer';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


const WhyWeExist = (props) => {
    const [slideOverlay, setSlideOverlay] = React.useState('');
    const [lightBoxOpen, setLightBoxOpen] = useState(false);
    
    const handleSlideOverlay = (cssClass) => {
        setSlideOverlay(cssClass);
    }
    const handleLightBoxVideo = (flag) => {        
        setLightBoxOpen(flag);
        if (flag) {
            disableBodyScroll(document.querySelector('body'));
        } else {
            clearAllBodyScrollLocks();
        }
    }

 
    return (
        <>
        <div className="videoWhyweexistContainer" id="why-section">
            <div className="children-video-wrapper">
                <img src="https://movement-assets.imgix.net/media/movementfoundation/top-frame.png" className="top-frame " alt="top-frame"/>
                <img src="https://movement-assets.imgix.net/media/movementfoundation/bottom-frame.png" className="bottom-frame " alt="top-frame"/>
                
                <VideoPlayer onVideoStart={handleSlideOverlay} />   
                <img src="https://movement-assets.imgix.net/media/movementfoundation/kids_new.jpg" className="kids-bg" alt="kids"/>
                <img src="https://movement-assets.imgix.net/media/movementfoundation/down-circle.svg" className="circle-down" alt="down-arrow-circle"/>
                
                
                <div className={`${lightBoxOpen ? 'modal-video' : 'hide-light-box'}`} id="youtube-player">
                    <div className="modal-video-body">
                        <div className="modal-video-inner">
                            <div className="modal-video-movie-wrap">
                                <button className="modal-video-close" aria-label="Close the modal by clicking here" onClick={() => handleLightBoxVideo(false)}></button>
                                <YouTubePlayer videoId="biy6OAvUFbI" isOpen={lightBoxOpen}/>
                            </div> 
                        </div>
                    </div>
                </div>    
            </div>
            <div className="playandWhyweexist">

            <div>
              {/* eslint-disable-next-line  */}
            <a id="play-video" className={`video-play-button show-mobile-table ${lightBoxOpen ? 'hidePlayBtn' : ''}`} onClick={() => handleLightBoxVideo(true)}>
                <span></span>
            </a>
            </div>
            <div className={`play-btn-overlay ${slideOverlay}`}>            
                
                <div className={`why-we-txt  `}>
                    <h2>Why we exist</h2>
                    <p  className="p-txt-1">We exist to love and value people by leading a movement of change in our industry, in corporate culture, and in communities.</p>
                    <p className="p-txt-2">Movement Foundation is the legal entity we work through to use our profits to uplift others – bringing life to our commitment to loving our neighbors as ourselves.
                    </p>
                </div>
            </div>
            </div>
            </div>
        </>
    );
};

export default WhyWeExist;
