import React, { Component } from 'react';
import '../../App.css'
import './invest.css'


class Invest extends Component{
    render(){
        return(
            <section id="invest-section">
            <div className="invest-container">
                <div className="invest-wrapper">
              <h1>How we invest</h1>
              <p>Our projects are intentionally designed to be self-sustaining, which is why our schools, health clinics and housing projects can flourish without ongoing fundraising. </p>
              <p>We partner with other businesses and not-for-profit organizations because the challenges facing society are too big to face alone, but we believe that we can achieve great things when we work together.</p>
              </div>
            </div>
            </section>
        )
    };
}

export default Invest
