import React from 'react'
import Scroll from 'react-scroll'
import { SvgNavBall } from "./SvgNavBall";
import PropTypes from "prop-types";

export class Navigation extends React.Component {
  constructor(props) {

    super(props);

    this.state = {
      currentSection: props.currentSection,
      navToSection: props.currentSection,
    };

    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleActiveClass = this.handleActiveClass.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentSection !== this.props.currentSection) {
      this.setState({ currentSection: this.props.currentSection })
    }

  }

  render() {

    const { navToSection } = this.state

    return (
  
     
      <ul className={'c-navigation'} >
         
        <div className="bullet-container">
          <li className={this.handleActiveClass('why-section')}
            onMouseLeave={() => this.handleCurrentSection(navToSection)}
            onMouseEnter={() => this.handleCurrentSection('why-section')}
            onClick={() => this.handleNavigation('why-section')}><span className={'side-arrow-container'}> <SvgNavBall />  </span>
          WHY
        </li>

          <li className={this.handleActiveClass('how-section')}
            onMouseEnter={() => this.handleCurrentSection('how-section')}
            onMouseLeave={() => this.handleCurrentSection(navToSection)}
            onClick={() => this.handleNavigation('how-section')}><span className={'side-arrow-container'}> <SvgNavBall />  </span>
          HOW
        </li>

          <li className={this.handleActiveClass('invest-section')}
            onMouseEnter={() => this.handleCurrentSection('invest-section')}
            onMouseLeave={() => this.handleCurrentSection(navToSection)}
            onClick={() => this.handleNavigation('invest-section')}><span className={'side-arrow-container'}> <SvgNavBall />  </span>
          INVEST
        </li>
          <li className={this.handleActiveClass('schools-section')}
            onMouseEnter={() => this.handleCurrentSection('schools-section')}
            onMouseLeave={() => this.handleCurrentSection(navToSection)}
            onClick={() => this.handleNavigation('schools-section')}><span className={'side-arrow-container'}> <SvgNavBall />  </span>
          SCHOOLS
        </li>

          <li className={this.handleActiveClass('communities-section')}
            onMouseEnter={() => this.handleCurrentSection('communities-section')}
            onMouseLeave={() => this.handleCurrentSection(navToSection)}
            onClick={() => this.handleNavigation('communities-section')}><span className={'side-arrow-container'}> <SvgNavBall />  </span>
          COMMUNITY
        </li>
          <li className={this.handleActiveClass('teammates-section')}
            onMouseEnter={() => this.handleCurrentSection('teammates-section')}
            onMouseLeave={() => this.handleCurrentSection(navToSection)}
            onClick={() => this.handleNavigation('teammates-section')}><span className={'side-arrow-container'}> <SvgNavBall />  </span>
           TEAMMATES
         </li>

        </div>

      </ul >
    )
  }

  handleCurrentSection(scrollLocation) {
    this.setState({ currentSection: scrollLocation })
  }

  handleNavToSection(scrollLocation) {
    this.setState({ navToSection: scrollLocation })
  }

  handleNavigation(scrollLocation) {

    this.handleCurrentSection(scrollLocation);
    this.handleNavToSection(scrollLocation);

    this.props.handleModalOpen()

    Scroll.scroller.scrollTo(scrollLocation, {
      smooth: true,
      duration: 500
    })
  }

  

  handleActiveClass(sectionLocation) {
    if (this.state.currentSection === sectionLocation) {
      return 'active ';
    } else return 'inactive ';
  }

}


Navigation.propTypes = {
  handleModalOpen: PropTypes.func,
  currentSection: PropTypes.string
};

Navigation.defaultProps = {
  handleModalOpen: () => {
  },
  currentSection: ''
}

export default Navigation
