import React from 'react'
import PropTypes from 'prop-types'

export const SvgNavBall = ({width, height}, props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7">
  <g id="Ellipse_21" data-name="Ellipse 21" fill="#fff" stroke="#00a6cc" strokeWidth="1">
    <circle cx="3.5" cy="3.5" r="3.5" stroke="none"/>
    <circle cx="3.5" cy="3.5" r="3" fill="none"/>
  </g>
</svg>
);


SvgNavBall.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};

SvgNavBall.defaultProps = {
  height: '1.3em',
  width: '1.5em'
};

