import React, { Component, useLayoutEffect, useRef, useState } from 'react';
import styled from "styled-components";
import '../../App.css'
import './HowItWorks.css'

// const styles = {
//     backgroundImage: "url(" + "https://movement-assets.imgix.net/media/movementfoundation/coin.svg" + ")",
//     backgroundSize: "cover",
// 	backgroundRepeat: "no-repeat",
//     width: 99.25,
//     height: 48,
//     margin: "auto",
// };
/*Actions Left Styled Components */
const Div1 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  margin: auto;
  transform: rotate(${({ animate }) => (animate ? "-15deg" : "30deg")});
  transition: transform 1s;
`;
const Div2 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "30deg" : "-30deg")});
  transition: transform 1s;
`;
const Div3 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "-15deg" : "30deg")});
  transition: transform 1s;
`;
const Div4 = styled.img`
  min-width: 215px;
  max-width: 270.1px;
  width: 170%;
  margin: auto;
  transform: rotate(${({ animate }) => (animate ? "-10deg" : "-30deg")});
  transition: transform 2s;
`;
const Div5 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "-15deg" : "30deg")});
  transition: transform 1s;
`;
const Div6 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "30deg" : "-30deg")});
  transition: transform 1s;
`;
/*Actions Right Styled Components */
const HandRight = styled.img`
  width: 125%;
  min-width: 180px;
  max-width: 270.1px;
  margin: auto;
  transform: rotate(${({ animate }) => (animate ? "-10deg" : "-60deg")});
  transition: transform 2s;
`;
const CoinRight1 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "-15deg" : "30deg")});
  transition: transform 1s;
`;
const CoinRight2 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "30deg" : "-30deg")});
  transition: transform 1s;
`;
const CoinRight3 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "-15deg" : "30deg")});
  transition: transform 1s;
`;
const CoinRight4 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "30deg" : "-30deg")});
  transition: transform 1s;
`;
const CoinRight5 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "-15deg" : "30deg")});
  transition: transform 1s;
`;
const CoinRight6 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "30deg" : "-30deg")});
  transition: transform 1s;
`;
const CoinRight7 = styled.img`
  min-width: 70.25px;
  max-width: 99.25px;
  width: 70%;
  transform: rotate(${({ animate }) => (animate ? "-15deg" : "30deg")});
  transition: transform 1s;
`;

export function ActionsLeft() {
    const [show, doShow] = useState({
        itemOne: false,
        itemTwo: false,
        itemThree: false,
        itemFour: false,
        itemFive: false,
        itemSix: false
        // itemSeven: false
      });
      const ourRef = useRef(null),
        anotherRef = useRef(null),
        refThree = useRef(null),
        refFour = useRef(null),
        refFive = useRef(null),
        refSix = useRef(null);
        // refSeven = useRef(null)
    
      useLayoutEffect(() => {
        const topPos = element => element.getBoundingClientRect().top;
        const div1Pos = topPos(ourRef.current),
          div2Pos = topPos(anotherRef.current),
          div3Pos = topPos(refThree.current),
          div4Pos = topPos(refFour.current),
          div5Pos = topPos(refFive.current),
          div6Pos = topPos(refSix.current);
        //   div7Pos = topPos(refSeven.current);
            
        const onScroll = () => {
          const scrollPos = window.scrollY + window.innerHeight;
            if (div1Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemOne: true }));
            }
            if (div2Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemTwo: true }));
            }
            if (div3Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemThree: true }));
            }
            if (div4Pos < scrollPos - 400) {
                doShow(state => ({ ...state, itemFour: true }));
            }
            if (div5Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemFive: true }));
            }
            if (div6Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemSix: true }));
            }
        };
    
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
      }, []);
    
    return (
        <div className="coin-section-content-container">
            <div className="animation-section-left">
                <div className="coin-cointainer-1">
                    <Div1 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemOne} ref={ourRef} />
                </div>
                <div className="coin-cointainer-2">
                    <Div2 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemTwo} ref={anotherRef} />
                </div>
                <div className="coin-cointainer-3">
                    <Div3 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemThree} ref={refThree}/>
                </div>
                <div className="speech-bubble-red-investors">
                    <img src="https://movement-assets.imgix.net/media/movementfoundation/investor.svg" alt="speech-bubble-red"></img>
                </div>
                <div className="hand-container">
                    <Div4 src="https://movement-assets.imgix.net/media/movementfoundation/hand-faceup.svg" animate={show.itemFour} ref={refFour}/>
                </div>
                <div className="investors-text-container">
                    Part of their profits are paid to shareholders.
                </div>
                
                <div className="wall-street-image">
                    <img src="https://movement-assets.imgix.net/media/movementfoundation/wall-st.svg" alt="wall-street-sign"></img>
                </div>
                <div className="bottom-left-yellow-arrow">
                    <img src="https://movement-assets.imgix.net/media/movementfoundation/coins-yellow-arrow-bottom.svg" alt="yellow-arrow"></img>
                </div>

                <div className="coin-cointainer-4">
                    <Div5 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemFive} ref={refFive}/>
                </div>
                <div className="coin-cointainer-5">
                    <Div6 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemSix} ref={refSix}/>
                </div>
                <div className="speech-bubble-red-leftover">
                    <img src="https://movement-assets.imgix.net/media/movementfoundation/leftover.svg" alt="speech-bubble-red-leftover"></img>
                </div>

                <div className="leftover-text-container">
                    Some of what remains may find its way to charity.
                </div>

                <div className="tiny-stack-of-coins-left">
                    <img src="https://movement-assets.imgix.net/media/movementfoundation/tiny-stack-of-coins-left.svg" alt="coin-stack"></img>
                </div>
            </div>
        </div>
    );
}

export function ActionsRight() {
    const [show, doShow] = useState({
        itemOne: false,
        itemTwo: false,
        itemThree: false,
        itemFour: false,
        itemFive: false,
        itemSix: false,
        itemSeven: false,
        itemEight: false
      });
      const ourRef = useRef(null),
        anotherRef = useRef(null),
        refThree = useRef(null),
        refFour = useRef(null),
        refFive = useRef(null),
        refSix = useRef(null),
        refSeven = useRef(null),
        refEight = useRef(null);
    
      useLayoutEffect(() => {
        const topPos = element => element.getBoundingClientRect().top;
        const div1Pos = topPos(ourRef.current),
          div2Pos = topPos(anotherRef.current),
          div3Pos = topPos(refThree.current),
          div4Pos = topPos(refFour.current),
          div5Pos = topPos(refFive.current),
          div6Pos = topPos(refSix.current),
          div7Pos = topPos(refSeven.current),
          div8Pos = topPos(refEight.current);
            
        const onScroll = () => {
          const scrollPos = window.scrollY + window.innerHeight;
            if (div1Pos < scrollPos - 400) {
                doShow(state => ({ ...state, itemOne: true }));
            }
            if (div2Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemTwo: true }));
            }
            if (div3Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemThree: true }));
            }
            if (div4Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemFour: true }));
            }
            if (div5Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemFive: true }));
            }
            if (div6Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemSix: true }));
            }
            if (div7Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemSeven: true }));
            }
            if (div8Pos < scrollPos - 300) {
                doShow(state => ({ ...state, itemEight: true }));
            }
        };
    
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
      }, []);
    
    return (
        <div className="coin-section-content-container">
            <div className="animation-section-right">
                <div className="upsidedown-hand-container">
                    <HandRight src="https://movement-assets.imgix.net/media/movementfoundation/hand-facedown.svg" animate={show.itemOne} ref={ourRef} />
                </div>
                <div className="speech-bubble-yellow-right">
                    <img src="https://movement-assets.imgix.net/media/movementfoundation/more-to-give-new-font.svg" alt="move-to-give"></img>
                </div>
                <div className="more-to-give-text-container">
                With no investors to repay, more profit goes to Movement Foundation to help uplift our communities.                </div>
                <div className="right-coin-cointainer-1">
                    <CoinRight1 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemTwo} ref={anotherRef} />
                </div>
                <div className="right-coin-cointainer-2">
                    <CoinRight2 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemThree} ref={refThree}/>
                </div>
                <div className="right-coin-cointainer-3">
                    <CoinRight3 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemFour} ref={refFour}/>
                </div>
                <div className="right-coin-cointainer-4">
                    <CoinRight4 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemFive} ref={refFive}/>
                </div>
                <div className="right-coin-cointainer-5">
                    <CoinRight5 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemSix} ref={refSix}/>
                </div>
                <div className="right-coin-cointainer-6">
                    <CoinRight6 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemSeven} ref={refSeven}/>
                </div>
                <div className="right-coin-cointainer-7">
                    <CoinRight7 src="https://movement-assets.imgix.net/media/movementfoundation/coin.svg" animate={show.itemEight} ref={refEight}/>
                </div>
                <div className="big-bucket-coin-stack">
                    <img src="https://movement-assets.imgix.net/media/movementfoundation/big-bucket-coin-stack.svg" alt="bucket-of-coins"></img>
                </div>
            </div>
        </div>
    );
}

class HowItWorks extends Component{
    render(){
        return(
            <div className="howitworks-component" id="how-section">
                <div className="coin-section-header">
                    <h1>HOW IT WORKS</h1>
                    <div className="subheader">             
                        <p>Like other companies, Movement Mortgage pays salaries, taxes, and operating expenses before seeing a profit. But with no investors to repay, the company has more resources for generous investment in communities.
                        </p>
                    </div>
                </div>
                <div className="coin-section-top">
                    <div className="other-companies-container">
                        <div className="other-companies-header">
                            OTHER COMPANIES
                        </div>
                        <div className="other-companies-subheading">
                            They make money.
                        </div>
                        <div className="top-yellow-arrow"></div>
                        <div className="coin-section-left">
                            <ActionsLeft/>
                        </div>
                    </div>

                    <div className="profit-container">
                        <div className="profit-stack-of-coins-container">
                           <img src="https://movement-assets.imgix.net/media/movementfoundation/stack-of-coins-top.svg" alt="stack-of-coins"></img>
                           <div className="line-container"></div>
                        </div>
                        <div className="speech-bubble-profit-container">
                            <img src="https://movement-assets.imgix.net/media/movementfoundation/profit.svg" alt="profit"></img>
                        </div>
                    </div>

                    <div className="mortgage-process-container">
                        <div className="mortgage-process-header">
                            MOVEMENT MORTGAGE
                        </div>
                        <div className="movement-mortgage-subheading">
                            Movement makes money, too.
                        </div>
                        <div className="top-yellow-arrow"></div>
                        <div className="coin-section-right">
                            <ActionsRight/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default HowItWorks
