import React from 'react'
import PropTypes from 'prop-types'
import './CSS/style.css'


import MobileMenu from "./MobileMenu";
import {filter} from "lodash"
import DesktopMenu from "./DesktopMenu";


export class Layout extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentSection: null,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.detectSectionChange)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.detectSectionChange);
  }

  render() {

        return (
          <>
            <DesktopMenu currentSection={this.state.currentSection} />
            <MobileMenu currentSection={this.state.currentSection} />

            <div className={'l-layout'}>
              {this.props.children}
            </div>
          </>
        )
  }

  detectSectionChange = () => {
    const sections = [].slice.call(
      document.querySelectorAll(
        "#why-section, #how-section, #invest-section, #schools-section, #communities-section, #teammates-section"
      )
    );

    const nav = document.getElementsByClassName('c-mobile-menu')[0];
    const navCoords = {
      "y": nav.getBoundingClientRect().y,
      "height": nav.getBoundingClientRect().height
    };

    const allSectionsCoords = sections.map(section => {
      return (
        {
          "y": section.getBoundingClientRect().y,
          "height": (section.getBoundingClientRect().height - navCoords.height),
          "section": section.id
        }
      )
    });

    const currentSection = filter(allSectionsCoords, sectionCoord => sectionCoord.y < navCoords.y + navCoords.height &&
      sectionCoord.y + sectionCoord.height > navCoords.y)

    this.setState({currentSection: currentSection[0] ? currentSection[0].section : null});

  }

}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
