import React, { useLayoutEffect, useRef, useState } from 'react';
import '../../App.css'
import './videogallery.css'
import styled from "styled-components";
import YouTubePlayer from '../YoutubePlayer/YoutubePlayer';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const Backpack = styled.div`
  height: 280px;
  width: 220px;
  margin: auto;
  background-image: url("https://movement-assets.imgix.net/media/movementfoundation/backpack.svg");
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateY(${({ animate }) => (animate ? "0" : "300px")});
  transition: transform 2s;
`;
const Plane = styled.div`
  height: 9.9vw;
  width: 23.6vw;
  margin: auto;
  position: absolute;
  background-image: url("https://movement-assets.imgix.net/media/movementfoundation/plane-new.svg");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 3px;
  transform: translateX(${({ animate }) => (animate ? "-250px" : "400px")});
  transition: transform 2s;
`;

export function VideoGalleryAnimations() {
  const [show, doShow] = useState({
      itemOne: false
    });
    const ourRef = useRef(null)
  
    useLayoutEffect(() => {
      const topPos = element => element.getBoundingClientRect().top;
      const div1Pos = topPos(ourRef.current);
          
        const onScroll = () => {
        const scrollPos = window.scrollY + window.innerHeight;
        if (div1Pos < scrollPos - 100) {
          doShow(state => ({ ...state, itemOne: true }));
        }
      };
  
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }, []);
  
  return (
      <div className="video-backpack">
          <Backpack animate={show.itemOne} ref={ourRef}/>
      </div>
  );
}
export function VideoGalleryAnimations2() {
  const [show, doShow] = useState({
      itemOne: false
    });
    const ourRef = useRef(null)
  
    useLayoutEffect(() => {
      const topPos = element => element.getBoundingClientRect().top;
      const div1Pos = topPos(ourRef.current);
          
        const onScroll = () => {
        const scrollPos = window.scrollY + window.innerHeight;
        if (div1Pos < scrollPos - 100) {
          doShow(state => ({ ...state, itemOne: true }));
        }
      };
  
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }, []);
  
  return (
    <div className="video-plane">
      <Plane animate={show.itemOne} ref={ourRef}/>
    </div>
  );
}

const VideoGallery = (props) => {
    const [videoID, setvideoID] = useState('');
    // const [setSlideOverlay] = React.useState('');
    const [lightBoxOpen, setLightBoxOpen] = useState(false);
    // const handleSlideOverlay = (cssClass) => {
    //     setSlideOverlay(cssClass);
    // }
    const handleLightBoxVideo = (flag, videoID) => { 
        setvideoID(videoID);
        setLightBoxOpen(flag);
        if (flag) {
            disableBodyScroll(document.querySelector('body'));
        } else {
            clearAllBodyScrollLocks();
        }
    }
        return(
          <> 
          <section id="schools-section">
          <div className="video-section-arrow-container">
          <img className="video-section-arrow" src="https://movement-assets.imgix.net/media/movementfoundation/video-section-arrow.svg" alt="arrow"></img>
          </div>
            <div className="video-gallery-container">
            <div className="text-container">
              <h1>Movement Schools</h1>
              <p>We believe education can transform the trajectory of young lives in every city that we serve.</p>
              <p>Movement Schools is a free, public charter school network committed to teaching world-class academics and virtues in areas where families have historically lacked access to education options. We purchase and provide amazing educational facilities for the independently operated schools to lease at below-market rates. </p>
              <div className="link-container">
                        <a href="https://www.movementschool.com/" target="_blank" rel="noopener noreferrer">MOVEMENT SCHOOLS</a><br/>
                        </div>
                    </div>   
                <div className="photo-container schools-photo-container">
                <img className="photo-bg" src="https://movement-assets.imgix.net/media/movementfoundation/schools-vid-sect-923.jpg" alt="schools"></img>
                <img className="photo-bg-tablet" src="https://movement-assets.imgix.net/media/movementfoundation/schools-vid-sect-923-mobile.jpg" alt="school-mobile"></img>
                <div className="school-shadow"></div>
                <VideoGalleryAnimations/>
                <div className="video-btn">
                {/* eslint-disable-next-line  */}
                <a id="play-video" onClick={() => handleLightBoxVideo(true, 'pLbth36YzlQ')} >
                  <img src="https://movement-assets.imgix.net/media/movementfoundation/video-btn.png" alt="video-play"></img>
                  </a>
                  </div>
                </div>
              </div>
              <div className={`${lightBoxOpen ? 'modal-video' : 'hide-light-box'}`} id="youtube-player">
                    <div className="modal-video-body">
                        <div className="modal-video-inner">
                            <div className="modal-video-movie-wrap">
                                <button className="modal-video-close" aria-label="Close the modal by clicking here" onClick={() => handleLightBoxVideo(false)}></button>
                                <YouTubePlayer videoId={videoID} isOpen={lightBoxOpen}/>
                            </div> 
                        </div>
                    </div>
                </div>  
              </section>

              <section id="communities-section">
            <div className="video-gallery-container">
            <div className="text-container">
              <h1>Movement Communities</h1>
              <p>We believe in helping communities take root and flourish. By repurposing property surrounding Movement Schools, we form community centers, low or no-cost shared workspaces for community-focused nonprofits, and spaces for after-school mentoring. We also introduce affordable housing and wellness centers for the uninsured and underinsured, right next door.</p>
              <div className="link-container">
                        <a href="https://movement.com/impactreport/" target="_blank" rel="noopener noreferrer">IMPACT REPORT</a><br/>
                        </div>
                    </div>     
                <div className="photo-container">
                <img className="photo-bg" src="https://movement-assets.imgix.net/media/movementfoundation/communities-vid-sect.jpg" alt="communities"></img>
                <img className="photo-bg-tablet" src="https://movement-assets.imgix.net/media/movementfoundation/communities-vid-sect-mobile-922.jpg" alt="communities-mobile"></img>
                <div className="video-btn">
                {/* eslint-disable-next-line  */}
                <a id="play-video" onClick={() => handleLightBoxVideo(true, 'natWDd_Dqx0')} >

                  <img src="https://movement-assets.imgix.net/media/movementfoundation/video-btn.png" alt="video-play-button"></img>
                  </a>
                  </div>                </div>
              </div>
            
              </section>

              <section id="teammates-section">
              <VideoGalleryAnimations2/>
            <div className="video-gallery-container">
            {/* <VideoGalleryAnimations2/> */}
            <div className="text-container">
              <h1>Movement Teammates</h1>
              <p>Movement employees are some of our most powerful ambassadors, and we invest in them.</p><p>Our Match Giving program amplifies the generosity of others, so when employees give to a cause that’s important to them, we match it dollar for dollar — or donate to match the time they volunteer. </p><p>Our employee-funded Love Works initiative supports co-workers dealing with unexpected needs such as emergency expenses or crisis counseling. </p><p>About once a month, small groups head out on one and two-week Vision Trips to do humanitarian work in places like Thailand, Mexico, Guatemala and Uganda. </p>
              <div className="link-container">
                        <a href="https://movementmissions.servicereef.com/" target="_blank" rel="noopener noreferrer">VISION TRIPS</a><br/>
                        </div>
                    </div>     
                <div className="photo-container">
                  <img className="photo-bg" src="https://movement-assets.imgix.net/media/movementfoundation/teammates-vid-sect.jpg" alt="teammates"></img>
                  <img className="photo-bg-tablet" src="https://movement-assets.imgix.net/media/movementfoundation/teammates-vid-sect-mobile-922.jpg" alt="teammates-mobile"></img>
                  <div className="video-btn">
                    {/* eslint-disable-next-line  */}
                    <a id="play-video" onClick={() => handleLightBoxVideo(true, 'RgkQ8eaV69E')} >

                      <img src="https://movement-assets.imgix.net/media/movementfoundation/video-btn.png" alt="video-play"></img>
                    </a>
                  </div>                
                </div>
              </div>
             
              </section>


</>
              
        )
    };

export default VideoGallery
