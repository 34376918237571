import React from 'react'
import {Navigation} from "./Navigation"

export class DesktopMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollision: false
    };

    this.desktopMenu = React.createRef();
    this.detectNavInSection = this.detectNavInSection.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.detectNavInSection)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isCollision !== this.state.isCollision) {
      this.desktopMenu.current.classList.toggle('desktop-menu') //color change
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.detectNavInSection);
  }

  render() {
    return (
      <div className={'c-desktop-menu'} ref={this.desktopMenu}>
        <Navigation currentSection={this.props.currentSection} />
      </div>
    )
  }

  detectNavInSection = () => {
    const sections = [].slice.call(
      document.querySelectorAll(
        ".content-section, .l-cta-footer, .l-ctas-footer"
      )
    );

    const allSectionsCoords = [];
    sections.forEach(section => {
      allSectionsCoords.push(
        {
          "y": section.getBoundingClientRect().y,
          "height": section.getBoundingClientRect().height
        }
      )
    });

    const nav = document.getElementsByClassName('c-navigation')[0];
    const navCoords = {
      "y": nav.getBoundingClientRect().y,
      "height": nav.getBoundingClientRect().height
    };

    const collisions = [];
    allSectionsCoords.forEach(sectionCoords => {
      if (sectionCoords.y < navCoords.y + navCoords.height &&
        sectionCoords.y + sectionCoords.height > navCoords.y) {
        collisions.push(true)
      }
    });

    this.setState({isCollision: collisions.includes(true)})

  }
}
export default DesktopMenu
