import React, { Component } from 'react';
import './LoveWorks.css'

class LoveWorks extends Component  {

  componentDidMount(){
    const script = document.createElement("script");
    script.type="text/javascript";
    // script.setAttribute('app_id','579a558bb2cecce624c1fe90');
    window.app_id='579a558bb2cecce624c1fe90';
    window.distribution_key='dist_3'
    window.api_domain='knack.com'
    // script.setAttribute('distribution_key','dist_4')
    // script.setAttribute('api_domain','knack.com')
    script.src = "https://loader.knack.com/579a558bb2cecce624c1fe90/dist_3/knack.js";
    script.async = true;
    document.body.appendChild(script);
  }

    render() {
      return (
        <div className="app-container">
          <div id="knack-dist_3">Loading...</div>
        </div>
    );}
  }
  
  export default LoveWorks;