import React from 'react';
import { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
 
const YoutubePlayer = (props) => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
          start: 0,
          rel : 0,
        },
      };
      const [YT, setYT] = useState('');
     const _onReady = (event) => {
        // access to player in all event handlers via event.target
        setYT(event.target);
        event.target.pauseVideo();
      }
      useEffect(() => {
       if (props.isOpen) {
          YT.seekTo(0, true);
          YT.playVideo();
       } else {
           if (YT && YT !== '') {
               YT.pauseVideo();
           }
       }
      });
      
      return <YouTube videoId={props.videoId} opts={opts} onReady={_onReady} />;
}
export default YoutubePlayer;