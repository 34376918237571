import React from 'react';

import './Header.css';

const Header = () => {
    return (
        <>
        <header className="header">
            <img src="https://movement-assets.imgix.net/media/movementfoundation/wavy_lines.png" className="wavy-lines" alt="wavy-graphic"/>
            <div className="logo">
                <img src="https://movement-assets.imgix.net/media/movementfoundation/logo-img.svg" className="logo-img-header" alt="foundation-logo"/>
                <h4 className="logo-txt">Movement Foundation</h4>
            </div>
            <div className="hope-title">
                Movement foundation brings <span className="yellow-color"> life, light &amp; Hope</span> to communities
            </div>
        </header>
        </>
    );
};

export default Header;
