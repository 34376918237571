import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import {
  HowItWorks,
  Layout,
  Header,
  Invest,
  Footer,
  VideoGallery,
  WhyWeExist,
  LoveWorks
} from './Components/index'

class App extends Component {

  render() {
    return (
      <div className="container">

        <Router>
          <div className="relative-container">
            <Header />
          </div>
          <Switch>
            <Route
              exact path='/loveworks-application'
              render={() => <LoveWorks />}
            />
            <Route 
              exact path='/match-giving-application'
              render={()=> Redirect('/')}
            />
            <Route
              exact path='/'
              render={props =>
                <div>
                  <WhyWeExist />
                  <HowItWorks />
                  <Invest />
                  <div className="videogallery-plane-relative-container"><VideoGallery /></div>
                  <Layout />
                </div>
              }
            />
          </Switch>
          <Footer />
        </Router>
      </div>
    );
  }
}

export default App;


