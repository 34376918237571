import React from 'react';
import {Navigation} from "./Navigation";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export class MobileMenu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.mobileMenu = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentSection !== this.props.currentSection) {
      switch (this.props.currentSection) {
        case 'intro-section':
        case 'industry-section':
        case 'outro-section':
          this.removeExistingMobileMenuColors();
          this.mobileMenu.current.classList.add('mobile-menu--red');
          break;
        case 'culture-section':
          this.removeExistingMobileMenuColors();
          this.mobileMenu.current.classList.add('mobile-menu--green');
          break;
        case 'community-section':
          this.removeExistingMobileMenuColors();
          this.mobileMenu.current.classList.add('mobile-menu--blue');
          break;
        default:
          this.removeExistingMobileMenuColors();
          this.mobileMenu.current.classList.add('mobile-menu--red');
          break;
      }
    }
  }


  render() {
    return (
      <nav className={'c-mobile-menu mobile-menu--red'} ref={this.mobileMenu}>
        <div onClick={this.handleModalOpen} className={(this.state.isOpen) ? 'hamburger is-active' : 'hamburger'}
             id="hamburger">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
        {this.renderMenu(this.state.isOpen)}
      </nav>
    )
  }

  renderMenu(isOpen) {
    if (isOpen) {
      return (
        <div className="light-box-bg">
          <div className="mobile-menu-dropdown">
          <Navigation currentSection={this.props.currentSection} handleModalOpen={this.handleModalOpen}/>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  handleModalOpen = () => {
    if (!this.state.isOpen) {
      disableBodyScroll(document.querySelector('body'));
    } else {
        clearAllBodyScrollLocks();
    }
    this.setState({isOpen: !this.state.isOpen})

  };

  removeExistingMobileMenuColors() {
    this.mobileMenu.current.classList.remove('mobile-menu--blue');
    this.mobileMenu.current.classList.remove('mobile-menu--red');
    this.mobileMenu.current.classList.remove('mobile-menu--blue')
  }


}
export default MobileMenu
